import { generalMixin } from "@/utils/general-mixin";
import { mapGetters } from "vuex";
// import cloneDeep from 'lodash/cloneDeep'

/*
  This is used by (please amend accordingly)
  1. Advertisement management

  DO NOT CHANGE ANYTHING HERE,
  you can only override anything in your own component

  NOTE :
  -All members/BO user can buy advertisement but can only view OWN purchased & approval list
  -BO user can access ad detail page from complain page
    -Admin can view/edit/archive ad from complain ad's url
    -Manager can view/edit ad from complain ad's url
    -Officer can only view ad from complain ad's url

  **** Only status "ACTIVE" && "PENDING" are editable

*/

export const adsMixin = {
  mixins: [generalMixin],
  watch: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    checkAccessRight(action,userId) {
      const isOwner = userId == this.user.id;
      switch (action) {
        case "edit":
          return this.fullAccess || isOwner;
        case "archive":
          return this.isAdmin || isOwner;
        default:
          return false;
      }
    },
  },
  mounted() {},
};
