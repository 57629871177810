<template>
  <div id="advertisement-detail" v-loading="isLoading">
    <div class="ads-content">
      <span class="static-block">
        <b>{{ $t('AD_TYPE') }} : </b> {{ $t(positionObj.position) }}
      </span>
      <span class="static-block">
        <b>{{ $t('AD_COST') }} : </b> {{ $t(positionObj.coin) }} {{ $t('COINS') }}
      </span>
      <span class="static-block">
        <b>{{ $t('AVAILABLE_COIN') }} : </b> {{ user.availableCoins }} {{ $t('COINS') }}
      </span>
    </div>
    <div class="break-line"></div>

    <el-row :gutter="40" class="ads-content" :class="positionObj.position">
      <el-col :xs="24" :md="12">
        <div class="el-form-item">
          <label for="" class="el-form-item__label">{{
            $t("ARTICLE_NAME")
          }}</label>
          <el-input
            disabled
            :value="articleObj.name | translate"
            type="textarea"
            resize="none"
            :rows="2"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :md="12">
        <div class="el-form-item">
          <label for="" class="el-form-item__label">{{
            $t("ARTICLE_TITLE")
          }}</label>
          <el-input
            disabled
            :value="articleObj.title | translate"
            type="textarea"
            resize="none"
            :rows="2"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
      </el-col>
      <el-form
        ref="form"
        :model="adsForm"
        :rules="rules"
        label-position="top"
        :disabled="isEditing && !isEditable('edit')"
        @submit.native.prevent="handleSubmit"
      >
        <el-col :xs="12" :md="5">
          <el-form-item
            :label="$t('START_DATE')"
            prop="fromDate"
            class="is-required"
          >
            <el-date-picker
              class="w-100"
              v-model="adsForm.fromDate"
              :disabled="!isAdding"
              :size="isMobile ? 'mini' : null"
              placement="bottom-start"
              :picker-options="startDateOptions"
              :clearable="false"
              prefix-icon="custom-icon icon-calendar mini"
              :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
              value-format="timestamp"
              @focus="e => e.blur()"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="12" :md="5">
          <el-form-item
            :label="$t('END_DATE')"
            prop="toDate"
            class="is-required"
          >
            <el-date-picker
              class="w-100"
              v-model="adsForm.toDate"
              :size="isMobile ? 'mini' : null"
              placement="bottom-start"
              :clearable="false"
              :picker-options="endDateOptions"
              prefix-icon="custom-icon icon-calendar mini"
              :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
              value-format="timestamp"
              @focus="e => e.blur()"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="9">
          <el-form-item :label="$t('ADVERTISEMENT_LINK')" prop="advertisementLink">
            <el-input
              type="url"
              placeholder="https://example.com"
              v-model="adsForm.advertisementLink"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="5">
          <el-form-item :label="$t('STATUS')" prop="status">
            <el-select
              class="w-100"
              disabled
              v-model="adsForm.status"
              :class="getStatusClass(adsForm.status)"
            >
              <el-option
                v-for="item in allSelectableStatusListFor(adsForm.status)"
                :key="item.value"
                :label="$t(item.value)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="24">
          <el-tabs
            tab-position="top"
            class="detail-language-options"
            type="card"
            v-model="selectedLanguage"
            @tab-click="adsTabId++"
          >
            <el-tab-pane v-for="el in getLangList()" :key="el.code" :name="el.code">
              <el-row type="flex" align="middle" slot="label">
                <img
                  class="custom-icon icon-lang mini"
                  :src="getLangFlagImage(el.code)"
                />
                <span
                  v-if="!isMobile || el.code === 'hi' || el.code === 'ta'"
                  class="lang-text"
                >{{ el.label }}</span>
                <i v-if="showCheckedIcon(el.code)" class="custom-icon el-icon-check mini text-green icon-checked" />
              </el-row>
            </el-tab-pane>
            <el-row>
             <el-col :xs="24" :md="12">
              <el-form-item
                :label="$t('ADVERTISEMENT_FILE',selectedLanguage)"
                prop="fileUploadList"
                class="is-required file-upload"
              >
                <image-upload
                  :photoUploadList.sync="adsForm.fileUploadList"
                  :language="{ id: selectedLanguageId, code: selectedLanguage }"
                  :replicateToAllLanguage="isReplicate"
                  isForm
                  :changeDeleteVertically="positionObj.position !== 'TOP_OF_SCREEN' && positionObj.position !== 'MIDDLE_OF_SCREEN'"
                />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tabs>
        </el-col>        
      </el-form>
    </el-row>

    <page-footer
      v-if="canApproval"
      :isLoading="isLoading"
      :handleBackTo="exit"
      :handleSubmit="() => handleApproval('APPROVE')"
      :submitBtnText="$t('APPROVE')"
      :handleAction="() => handleApproval('REJECT')"
      customActionClass="button red link-style"
      :actionBtnText="$t('REJECT')"
    />

    <page-footer
      v-else
      :isLoading="isLoading"
      :handleBackTo="exit"
      :handleArchive="showArchiveBtn ? () => handleArchive() : null"
      :handleSubmit="showSubmitBtn? () => handleSubmit() : null"
      :submitBtnText="$t('SAVE')"
      :handleAction="showSubmitBtn ?  () => handlePreview() : null"
      :actionBtnText="$t('PREVIEW')"
    />
  </div>
</template>


<script>
import { generalMixin } from "@/utils/general-mixin.js";
import { adsMixin } from '@/utils/_mixins/advertisement'
import {
  getAdsPriceById,
  addAds,
  getAdsbyId,
  archiveAds,
  updateAds,
  updateAdsApproval
} from "@/api/advertisement";
import { getBOArticleById } from "@/api/article.js";
import { mapGetters } from "vuex";
import ImageUpload from "@/components/image-upload.vue";
import PageFooter from "@/components/page-footer.vue";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

const formFields = {
  position: "",
  status: "ACTIVE",
  fromDate: moment().valueOf(),
  toDate: moment().valueOf(),
  user: {},
  article: {},
  fileUploadList: [],
  advertisementLink: "",
};

export default {
  name: "AdvertisementDetail",
  components: { PageFooter, 
    ImageUpload
  },
  mixins: [generalMixin,adsMixin],
  data() {
    return {
      articleId: Number(this.$route.params?.articleId) || null,
      advertisementId: Number(this.$route.params?.advertisementId) || null,
      approvalId: Number(this.$route.params?.approvalId) || null,
      positionId: Number(this.$route.query?.position) || null,
      isAdding: false,
      isEditing: false,
      isLoading: false,
      isViewApproval: false,
      isReplicate: true,
      positionObj: {},
      articleObj: {},
      adsForm: cloneDeep(formFields),
      rules: {
        advertisementLink: [
          {
            type: "url",
            message: this.$t('URL_INVALID'),
            trigger: "blur",
          },
        ],
        fileUploadList: [
          {
            required: true,
            message: this.$t("ADVERTISEMENT_IMAGE_REQUIRED"),
            trigger: "change",
          },
        ],
      },
      startDateOptions: {
        disabledDate (date) {
          const noPassDate = new Date() > date
          return noPassDate
        }
      },
      endDateOptions: {
        disabledDate: this.disabledDueDate
      },
      selectedLanguage: this.locale || "en",
      adsTabId: 0
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("app", ["getLangFlagImage", "getLangList"]),
    selectedLanguageId() {
      console.log(this.getLangList())
      let lang = this.getLangList().find(lang => lang.code == this.selectedLanguage)
      return lang.id
    },
    showSubmitBtn() {
      const canAdd = this.isAdding
      const canEdit = this.isEditing && this.isEditable('edit')
      return canAdd || canEdit
    },
    showArchiveBtn() {
      const canArchive = this.isEditing && this.isEditable('archive')
      return canArchive
    },
    canApproval() {
      return this.adsForm.status === "PENDING_APPROVAL_CREATE" 
          && this.isViewApproval 
          && this.approvalId
    }
  },
  beforeRouteEnter (_, from, next) {
    next(vm => {
      // console.log(from)
      if (from.query?.chosenTab === 'pending') {
        // console.log('view only')
        vm.isViewApproval = true;
      } else {
        vm.isViewApproval = false;
      }

      next()
    });
  }, 
  watch: {
    "adsForm.fileUploadList": {
      handler(newV) {
        if(newV.length == 0) {this.isReplicate = true}
        else this.isReplicate = false
      }
    }
  },
  methods: {
    showCheckedIcon(locale) {
      let isFound = false
      isFound = this.adsForm.fileUploadList.find( el => {
        return el.language.code === locale
      })
      return isFound
    },
    exit() {
      if(this.isAdding) this.$router.push('/bo/advertisement')
      else this.$router.back()
    },
    mountedFn() {
      if (!this.advertisementId) {
        this.getPositionById();
        this.getArticleById();
        this.isAdding = true;
      } else if (this.advertisementId) {
        this.getAdsById();
        this.isEditing = true
      }
      this.adsForm.article.id = this.articleId;
      this.adsForm.user.id = this.user.id;
    },
    disabledDueDate (time) {
        return time.getTime() < this.adsForm.fromDate
    },
    getPositionById() {
      getAdsPriceById(this.positionId)
        .then((res) => {
          if (res.status == "ok") {
            this.positionObj = res.result;
            this.adsForm.position = this.positionObj.position;
          }
        })
        .catch(this.handleError);
    },
    getArticleById() {
      this.isLoading = true;
      let pData = {
        id: this.articleId,
        getWhenCategoryInactive: true,
      };
      getBOArticleById(pData)
        .then((res) => {
          if (res.status == "ok") {
            this.articleObj = res.result;
          }
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    isEditable(action) {
      // NOTE - Only status "ACTIVE" is editable
      // Share page with "Approval" (View Access ONLY)
      const canEdit = (!this.isViewApproval && this.adsForm.status == "ACTIVE")
      const isEditAccess = this.isEditing && this.checkAccessRight(action,this.adsForm.user.id)
      return canEdit && isEditAccess;
    },
    getAdsById() {
      this.isLoading = true;
      getAdsbyId(this.advertisementId)
        .then((res) => {
          if (res.status == "ok") {
            this.articleObj = res.result.article;
            this.adsForm = res.result;
            this.articleId = this.adsForm.article.id;
            this.positionObj = this.adsForm;

            // if (!this.isEditable()) this.isEditing = false;
            // else this.isEditing = true;
          }
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleArchive() {
      archiveAds(this.advertisementId)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("ARCHIVED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
          }
        })
        .catch(this.handleError)
        .finally(() => this.exit());
    },
    handleEdit() {
      this.isLoading = true;
      let pData = {
          "id": this.advertisementId,
          "status": "ACTIVE",
          "toDate": this.adsForm.toDate,
          "fileUploadList": this.adsForm.fileUploadList
      }
      updateAds(pData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            this.exit();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    formValidation() {
      let verified = false
      this.$refs["form"].validate((valid) => {
        if (!valid || (this.adsForm.fileUploadList == null || this.adsForm.fileUploadList.length === 0)){
          verified = false;
        } else {
          verified = true;
        }
      });
      return verified
    },
    handleSubmit() {
      if(this.formValidation()) {
        if (this.isAdding) this.handleAdd();
        else this.handleEdit();
      }      
    },
    handleAdd() {
      this.isLoading = true;
      addAds(this.adsForm)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("BUY_AD_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            this.exit();
          } else {
            let insufficientCoins = res.errorCode == "request.error.insufficient.coins"
            console.log(insufficientCoins)
            if(insufficientCoins) {
              this.$notify({
                dangerouslyUseHTMLString: true,
                customClass: 'p2p-link',
                message: `<a href="/bo/p2p-market" target="_blank" class="button link link-style text-primary">${this.$t('GET_MORE_COIN_HERE')}</a>`,
                duration: 0,
                position: this.NOTIFY_POSITION
              });
            }
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePreview() {
      if(this.formValidation()) {
        console.log('preview',this.adsForm)
        this.$store.dispatch('advertisement/previewAds',this.adsForm)
        this.$nextTick(() => {
          this.goTo(`/00?locale=${this.selectedLanguage}`, true)
        })
      }
    },
    handleApproval(action) {
      this.$confirm(this.$t(action) + "?", this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("YES_WITH_ACTION", { action: this.$t(action) }),
        cancelButtonText: this.$t("CANCEL"),
        type: "warning",
      }).then(() => {
        let pData = {
          id: this.approvalId,
          actionStatus: action,
          actionBy: {
            id: this.user.id,
          },
        };
        updateAdsApproval(pData)
          .then((res) => {
            if (res.status == "ok") {
              this.$notify({
                type: "success",
                message: this.$t("UPDATE_SUCCESSFULLY"),
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION
              });
              this.exit()
            }
          })
          .catch(this.handleError)
      });
    },
  },
  mounted() {
    this.mountedFn();
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";

#advertisement-detail {
  @include detail-language-options;
  @include detail-form;

  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 1rem;
  .ads-content {
    padding: 0 0.8rem;
    margin-bottom: 1rem;
  }

  .static-block {
    background: #f3f5fa 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    letter-spacing: 0px;
    color: #666c79;
    padding: 10px;
    font-size: 12px;
    margin-right: 0.5rem;

    b {
      color: #253858;
    }
  }
  .break-line {
    border-top: 1px dashed #e8e8e8;
    margin-bottom: 1rem;
  }
  .image-upload.form .dashed-photo-container{
    width: 500px;
    height: 500px;
    position: relative;
    margin-bottom: 1rem;
  }
  .image-upload.form .el-upload, .image-upload.form .el-upload-dragger {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 600px;
    height: 100%;
  }
  .image-upload.form .change-delete-text-wrapper, .image-upload.form .upload-text{
    right: 0;
    left: 0%;
    top: 2%;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 500px
  }

  .file-upload {
    width: calc(100vw - $sidebarWidth - 80px);
    max-width: calc($viewMaxWidth - $sidebarWidth - 80px);
    height: 100%;
    min-height: 290px;
  }
  .RIGHT_SIDE_OF_SCREEN {
   .image-upload.form .dashed-photo-container{
      width: 262px;
      height: calc(262px * 3);
    }
    .image-upload.form .el-upload, .image-upload.form .el-upload-dragger {
      left: 0;
      top: 0%;
      display: block;
      height: 100%;
      width: calc(262px + 120px);
    }
    .image-upload.form .change-delete-text-wrapper, .image-upload.form .upload-text {
      height: 100%;
    }
  }
  .TOP_OF_SCREEN {
   .image-upload.form .dashed-photo-container{
      width: 100%;
      height: 196px;
    }
    .image-upload.form .el-upload, .image-upload.form .el-upload-dragger {
      left: 0;
      top: 0;
      width: calc(100vw - $sidebarWidth - 80px);
      max-width: calc($viewMaxWidth - $sidebarWidth - 80px);
      height: calc(196px + 60px);
      display: block;
      text-align: center;
    }
    .image-upload.form .change-delete-text-wrapper, .image-upload.form .upload-text{
      right: 0;
      left: 0%;
      top: 0;
      justify-content: center;
      align-items: end;
      width: 100%;
      height: 100%;
    }
    .icon-camera {
      margin-bottom: 5px;
    }
  }
  .MIDDLE_OF_SCREEN {
   .image-upload.form .dashed-photo-container{
      width: 1145px;
      height: 172px;
    }
    .image-upload.form .el-upload, .image-upload.form .el-upload-dragger {
      left: 0;
      top: 0;
      width: calc(100vw - $sidebarWidth - 80px);
      max-width: calc($viewMaxWidth - $sidebarWidth - 80px);
      height: calc(172px + 60px);
      display: block;
      text-align: center;
    }
    .image-upload.form .change-delete-text-wrapper, .image-upload.form .upload-text{
      right: 0;
      left: 0%;
      top: 0%;
      justify-content: center;
      align-items: end;
      width: 100%;
      height: 100%;
    }
    .icon-camera {
      margin-bottom: 5px;
    }
  }
  .detail-language-options {
    .icon-checked {
      font-weight: bolder;
      font-size: 20px;
      height: 24px;
    }
    .lang-text {
      margin: 0 5px;
    }
  }
}

.p2p-link {
  width: 210px;
  .el-notification__content {
    margin-top: 0;
    font-family: $fontBaseBold;
  }
}

.mobile #advertisement-detail {
  .static-block {
    display: block;
    margin: 10px 0;
  }
}
</style>